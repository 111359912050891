define("ui/components/form-node-scheduling-row/component", ["exports", "ui/components/form-node-scheduling-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PRIORITY = ['required', 'preferred'];

  var _default = Ember.Component.extend({
    layout: _template.default,
    editing: true,
    term: [],
    priority: 'required',

    init() {
      this._super(...arguments);

      this.initPriorityChoices();
    },

    actions: {
      remove() {
        if (this.remove) {
          this.remove(this.model);
        }
      }

    },
    termsChanged: Ember.observer('model.nodeSelectorTerms.matchExpressions.@each.{key,operater,values}', 'model.priority', function () {
      if (this.update) {
        this.update();
      }
    }),

    initPriorityChoices() {
      Ember.set(this, 'priorityChoices', PRIORITY.map(k => {
        return {
          translationKey: `nodeDriver.harvester.scheduling.input.priority.${k}`,
          value: k
        };
      }));
    }

  });

  _exports.default = _default;
});