define("ui/authenticated/route", ["exports", "jquery", "shared/utils/parse-version", "ui/mixins/preload", "ui/utils/constants"], function (_exports, _jquery, _parseVersion, _preload, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CHECK_AUTH_TIMER = 60 * 10 * 1000;

  var _default = Ember.Route.extend(_preload.default, {
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    cookies: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    modalService: Ember.inject.service('modal'),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    storeReset: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    userTheme: Ember.inject.service('user-theme'),
    testTimer: null,
    shortcuts: {
      // Global
      'c': 'gotoc',
      'u': 'gotou',
      'shift+k': 'gotoK',
      'shift+p': 'gotoP',
      'shift+t': 'gotoT',
      // Cluster or Proejct
      '`': 'quake',
      'shift+`': 'quake',
      'm': 'gotom',
      // Cluster
      'd': 'gotod',
      'n': 'goton',
      'p': 'gotop',
      'shift+v': 'gotoV',
      // Project
      'w': 'gotow',
      'b': 'gotob',
      's': 'gotos',
      'h': 'gotoh',
      'v': 'gotov',
      'a': 'gotoa',
      // Other
      // 'g': Defined in subroutes
      't': 'nextTab',
      '/': 'search',
      'shift+/': 'help',
      'backspace': 'delete',
      'delete': 'delete'
    },

    beforeModel() {
      Ember.set(this, 'testTimer', Ember.run.later(() => {
        this.testAuthToken();
      }, CHECK_AUTH_TIMER));
      return Ember.RSVP.all([this.testAuthToken(), this.loadPublicSettings()]).then(() => {
        if (Ember.get(this, 'access.mustChangePassword')) {
          this.transitionTo('update-password');
        }

        if (Ember.get(this, 'settings.serverUrlIsEmpty')) {
          Ember.get(this, 'router').transitionTo('update-critical-settings');
        }
      });
    },

    model(params, transition) {
      Ember.get(this, 'session').set(_constants.default.SESSION.BACK_TO, undefined);
      const isPopup = this.controllerFor('application').get('isPopup');
      return Ember.get(this, 'scope').startSwitchToGlobal(!isPopup).then(() => {
        const list = [this.loadSchemas('globalStore'), this.loadClusters(), this.loadProjects(), this.loadPreferences()];
        const globalStore = Ember.get(this, 'globalStore');

        if (!isPopup) {
          list.addObjects([this.preload('node', 'globalStore', {
            url: 'nodes'
          }), this.preload('nodePool', 'globalStore', {
            url: 'nodePools'
          }), this.preload('noedTemplates', 'globalStore', {
            url: 'nodeTemplates'
          }), this.preload('roleTemplate', 'globalStore', {
            url: 'roleTemplates',
            filter: {
              enabled: true,
              hidden: false
            }
          }), this.preload('clusterRoleTemplateBindings', 'globalStore', {
            url: 'clusterRoleTemplateBindings'
          }), this.preload('projectRoleTemplateBinding', 'globalStore', {
            url: 'projectRoleTemplateBinding'
          }), this.preload('globalRole', 'globalStore', {
            url: 'globalRole'
          }), this.preload('authConfig', 'globalStore', {
            url: 'authConfigs'
          }), this.preload('globalRoleBinding', 'globalStore', {
            url: 'globalRoleBinding'
          }), this.preload('user', 'globalStore', {
            url: 'user'
          }), this.preload('features', 'globalStore', {
            url: 'features'
          }), globalStore.findAll('principal').then(principals => {
            const me = principals.filter(p => p.me === true);

            if (me.length > 0) {
              Ember.set(this, 'access.principal', Ember.get(me, 'firstObject'));
            }

            principals.forEach(p => {
              Ember.set(p, '_mine', true);
            });
          })]);
        }

        return Ember.RSVP.all(list);
      }).catch(err => this.loadingError(err, transition));
    },

    afterModel() {
      return Ember.get(this, 'scope').finishSwitchToGlobal();
    },

    activate() {
      this._super(...arguments);

      if (this.controllerFor('application').get('isPopup')) {
        (0, _jquery.default)('BODY').addClass('popup');
        return;
      } // Don't show any modals when embedded


      const isEmbedded = window.top !== window;

      if (!Ember.get(this, `cookies.${_constants.default.COOKIE.REDIRECTED}`)) {
        // Send users to dashboard, if there's no redirect cookie, and not embedded
        // So that if you're on <2.6 in Ember, upgrade, and get reloaded you end up in Dashboard.
        this.cookies.set(_constants.default.COOKIE.REDIRECTED, true); // If isEmbedded then you're already in Dashboard, so just set the cookie.

        if (!isEmbedded) {
          window.location.href = Ember.get(this, 'scope.dashboardBase');
          return;
        }
      }

      if (Ember.get(this, 'settings.isRancher') && !isEmbedded) {
        const telemetry = Ember.get(this, `settings.${_constants.default.SETTING.TELEMETRY}`);
        const form = Ember.get(this, `settings.${_constants.default.SETTING.FEEDBACK_FORM}`);
        const seenWhatsNew = Ember.get(this, `prefs.${_constants.default.PREFS.SEEN_WHATS_NEW}`);
        const version = Ember.get(this, 'settings.rancherVersion');
        const isDev = (0, _parseVersion.isDevBuild)(version);

        if (Ember.get(this, 'access.admin') && (!telemetry || telemetry === 'prompt')) {
          // Show the telemetry opt-in if not set
          Ember.run.scheduleOnce('afterRender', this.modalService, 'toggleModal', 'modal-telemetry');
        } else if (form && !Ember.get(this, `prefs.${_constants.default.PREFS.FEEDBACK}`)) {
          // Try.rancher feedback
          Ember.run.scheduleOnce('afterRender', this.modalService, 'toggleModal', 'modal-feedback');
        } else if (!isDev && (!seenWhatsNew || (0, _parseVersion.compare)(seenWhatsNew, _constants.default.WHATS_NEW_VERSION) < 0)) {
          // What's new
          Ember.run.scheduleOnce('afterRender', this.modalService, 'toggleModal', 'modal-whats-new');
        }
      }
    },

    deactivate() {
      this._super();

      const scope = Ember.get(this, 'scope');
      scope.startSwitchToNothing().then(() => {
        scope.finishSwitchToNothing();
      });
      Ember.run.cancel(Ember.get(this, 'testTimer'));
    },

    actions: {
      error(err, transition) {
        // Unauthorized error, send back to login screen
        if (err && err.status === 401) {
          this.send('logout', transition);
          return false;
        } else {
          // Bubble up
          return true;
        }
      },

      becameReady() {// This absorbs became ready in case it's not handled elsewhere
      },

      showAbout() {
        this.controllerFor('application').set('showAbout', true);
      },

      switchProject(projectId, transitionTo = 'authenticated', transitionArgs) {
        // console.log('Switch to Project:' + projectId);
        Ember.RSVP.all([Ember.get(this, 'scope.subscribeProject').disconnect()]).then(() => {
          // console.log('Switch is disconnected');
          this.send('finishSwitch', `project:${projectId}`, transitionTo, transitionArgs);
        });
      },

      finishSwitch(id, transitionTo, transitionArgs) {
        // console.log('Switch finishing');
        const cookies = Ember.get(this, 'cookies');
        var [whichCookie, idOut] = id.split(':');
        Ember.get(this, 'storeReset').reset();

        if (transitionTo) {
          let args = (transitionArgs || []).slice();
          args.unshift(transitionTo);
          this.transitionTo.apply(this, args);
        }

        cookies.set(_constants.default.COOKIE[whichCookie.toUpperCase()], idOut);
        this.refresh(); // console.log('Switch finished');
      },

      help() {
        Ember.get(this, 'modalService').toggleModal('modal-shortcuts', {
          escToClose: true
        });
      },

      // Special
      nextTab() {
        if ((0, _jquery.default)('.tab-nav').length) {
          let cur = (0, _jquery.default)('.tab-nav .active');
          let next = cur.closest('li').next().find('a');

          if (next && next.length) {
            next.click();
          } else {
            next = (0, _jquery.default)('.tab-nav li:first-child a');

            if (next && next.length) {
              next.click();
            }
          }
        }
      },

      search(event) {
        let elem = (0, _jquery.default)("INPUT[type='search']")[0];

        if (elem) {
          event.stopPropagation();
          event.preventDefault();
          elem.focus();
        }
      },

      delete() {
        (0, _jquery.default)('.bulk-actions .icon-trash').closest('a').click();
      },

      // Global
      gotoc() {
        this._gotoRoute('global-admin.clusters.index', 'global');
      },

      gotou() {
        this._gotoRoute('global-admin.security.accounts.users', 'global');
      },

      gotoK() {
        this._gotoRoute('authenticated.apikeys', 'global');
      },

      gotoP() {
        this._gotoRoute('authenticated.prefs', 'global');
      },

      gotoT() {
        this._gotoRoute('nodes.node-templates', 'global');
      },

      // Cluster or Project
      gotom() {
        this._gotoMembers();
      },

      // Cluster
      gotod() {
        this._gotoRoute('authenticated.cluster.index', 'cluster');
      },

      goton() {
        this._gotoRoute('authenticated.cluster.nodes', 'cluster');
      },

      gotop() {
        this._gotoRoute('authenticated.cluster.projects', 'cluster');
      },

      gotoV() {
        this._gotoRoute('authenticated.cluster.storage.persistent-volumes', 'cluster');
      },

      quake() {
        const clusterId = Ember.get(this, 'scope.currentCluster.id');

        if (clusterId) {
          this.get('modalService').toggleModal('modal-kubectl', {});
        }
      },

      // Project
      gotow() {
        this._gotoRoute('containers', 'project');
      },

      gotob() {
        this._gotoRoute('ingresses', 'project');
      },

      gotos() {
        this._gotoRoute('authenticated.project.dns', 'project');
      },

      gotoh() {
        this._gotoRoute('authenticated.project.hpa', 'project');
      },

      gotov() {
        this._gotoRoute('volumes', 'project');
      },

      gotoa() {
        this._gotoRoute('apps-tab.index', 'project');
      }

    },

    testAuthToken() {
      return Ember.get(this, 'access').testAuth().catch(() => {
        Ember.set(this, `session.${_constants.default.SESSION.BACK_TO}`, window.location.href);
        this.transitionTo('login');
        this.send('logout', null);
      });
    },

    loadPreferences() {
      return Ember.get(this, 'globalStore').find('preference', null, {
        url: 'preference'
      }).then(res => {
        Ember.get(this, 'language').initLanguage(true);
        Ember.get(this, 'userTheme').setupTheme();

        if (Ember.get(this, `prefs.${_constants.default.PREFS.I_HATE_SPINNERS}`)) {
          (0, _jquery.default)('BODY').addClass('i-hate-spinners');
        }

        return res;
      });
    },

    loadClusters() {
      return Ember.get(this, 'scope').getAllClusters();
    },

    loadProjects() {
      return Ember.get(this, 'scope').getAllProjects();
    },

    loadPublicSettings() {
      return Ember.get(this, 'globalStore').find('setting', null, {
        url: 'settings'
      });
    },

    loadSecrets() {
      if (Ember.get(this, 'store').getById('schema', 'secret')) {
        return Ember.get(this, 'store').find('secret');
      } else {
        return Ember.RSVP.resolve();
      }
    },

    _gotoRoute(name, scope = 'global') {
      // Don't go to routes if in a form page, because you can easily not be on an input
      // and leave the page accidentally.
      if ((0, _jquery.default)('FORM').length > 0) {
        return;
      }

      const clusterId = Ember.get(this, 'scope.currentCluster.id');
      const projectId = Ember.get(this, 'scope.currentProject.id');

      if (scope === 'cluster' && clusterId) {
        this.transitionTo(name, clusterId);
      } else if (scope === 'project' && projectId) {
        this.transitionTo(name, projectId);
      } else if (scope === 'global') {
        this.transitionTo(name);
      }
    },

    _gotoMembers() {
      const clusterId = Ember.get(this, 'scope.currentCluster.id');
      const projectId = Ember.get(this, 'scope.currentProject.id');

      if (projectId) {
        this._gotoRoute('authenticated.project.security.members.index', 'project');
      } else if (clusterId) {
        this._gotoRoute('authenticated.cluster.security.members.index', 'cluster');
      }
    }

  });

  _exports.default = _default;
});