define("ui/components/form-node-scheduling-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w5phX1F3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"box mb-20\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"pb-5 acc-label\"],[14,\"for\",\"input-project-select\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"nodeDriver.harvester.scheduling.input.priority.label\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,4],null,[[\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"localizedLabel\"],[[35,3,[\"priority\"]],[35,2],\"value\",\"translationKey\",true]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col span-6 text-right mt-0\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn bg-link icon-btn\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"remove\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"icon icon-minus text-small\"],[12],[13],[2,\"\\n          \"],[10,\"span\"],[12],[1,[30,[36,1],[\"formNodeAffinity.removeRuleLabel\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,7],null,[[\"term\",\"initialRules\",\"editing\"],[[35,3,[\"nodeSelectorTerms\"]],false,[35,5]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"priorityChoices\",\"model\",\"new-select\",\"editing\",\"if\",\"form-node-requirement\"]}",
    "moduleName": "ui/components/form-node-scheduling-row/template.hbs"
  });

  _exports.default = _default;
});