define("ui/authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GuVrD7p0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"bg-warning\"],[14,1,\"azuread-warning-banner\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"authPage.azuread.updateEndpoint.banner.message\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,5],null,[[\"pageScope\",\"goToPrevious\"],[[35,4],\"goToPrevious\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"main\"],[14,0,\"clearfix\"],[12],[2,\"\\n  \"],[1,[30,[36,9],[[30,[36,8],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"showAbout\"],[\"showAbout\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page-footer\",\"t\",\"azureNeedsUpdate\",\"if\",\"pageScope\",\"page-header\",\"isPopup\",\"unless\",\"-outlet\",\"component\"]}",
    "moduleName": "ui/authenticated/template.hbs"
  });

  _exports.default = _default;
});